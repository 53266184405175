import React, { Component } from "react"
// import { Link } from "gatsby"
import Airtable from "airtable"
import classNames from "classnames"
import BodyClassName from "react-body-classname"
import CountUp from "react-countup"
import {
  Input,
  Buttons,
  Button,
  // Icon,
  Header,
  Stars
} from "../components/common"
import "../less/app.less"

Airtable.configure({
  endpointUrl: "https://api.airtable.com",
  apiKey: "keyJaT94OmfBVzZoK"
})
var base = Airtable.base("appvLPIhobpNYMVLr")

const easingFn = function(t, b, c, d) {
  if ((t /= d / 2) < 1) return (c / 2) * t * t * t * t + b
  return (-c / 2) * ((t -= 2) * t * t * t - 2) + b
}

const formatDollar = number => `$${new Intl.NumberFormat().format(number)}`

let nodeRef

const PageFooter = React.memo(({ back, next, submit, jump }) => (
  <div className="page-footer">
    <div className="page-footer-border" />
    <Buttons>
      {back && (
        <button onClick={back} className="button shadow back">
          Back
        </button>
      )}
      {next && (
        <button onClick={next} className="button primary next">
          Next
        </button>
      )}
      {jump && (
        <Button className="primary next" onClick={submit} to={jump}>
          Finish
        </Button>
      )}
    </Buttons>
  </div>
))

class IndexPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      stars: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      prevTotal: 0,
      score: 0,
      total: 0,
      email: "",
      values: {
        brokerage_asset: 0,
        cash_asset: 0,
        checking_asset: 0,
        credit_debt: 0,
        crypto_asset: 0,
        equity_asset: 0,
        home_asset: 0,
        home_debt: 0,
        hsa_asset: 0,
        medical_debt: 0,
        metals_asset: 0,
        other_asset: 0,
        other_debt: 0,
        property_asset: 0,
        property_debt: 0,
        retire_asset: 0,
        roth_asset: 0,
        savings_asset: 0,
        student_debt: 0
      }
    }
    this.handleNext = this.handleNext.bind(this)
    this.handleBack = this.handleBack.bind(this)
    this.handleNav = this.handleNav.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  componentDidMount() {
    if (typeof window !== "undefined") {
      const localValues = {
        brokerage_asset:
          JSON.parse(localStorage.getItem("brokerage_asset")) || 0,
        cash_asset: JSON.parse(localStorage.getItem("cash_asset")) || 0,
        checking_asset: JSON.parse(localStorage.getItem("checking_asset")) || 0,
        credit_debt: JSON.parse(localStorage.getItem("credit_debt")) || 0,
        crypto_asset: JSON.parse(localStorage.getItem("crypto_asset")) || 0,
        equity_asset: JSON.parse(localStorage.getItem("equity_asset")) || 0,
        home_asset: JSON.parse(localStorage.getItem("home_asset")) || 0,
        home_debt: JSON.parse(localStorage.getItem("home_debt")) || 0,
        hsa_asset: JSON.parse(localStorage.getItem("hsa_asset")) || 0,
        medical_debt: JSON.parse(localStorage.getItem("medical_debt")) || 0,
        metals_asset: JSON.parse(localStorage.getItem("metals_asset")) || 0,
        other_asset: JSON.parse(localStorage.getItem("other_asset")) || 0,
        other_debt: JSON.parse(localStorage.getItem("other_debt")) || 0,
        property_asset: JSON.parse(localStorage.getItem("property_asset")) || 0,
        property_debt: JSON.parse(localStorage.getItem("property_debt")) || 0,
        retire_asset: JSON.parse(localStorage.getItem("retire_asset")) || 0,
        roth_asset: JSON.parse(localStorage.getItem("roth_asset")) || 0,
        savings_asset: JSON.parse(localStorage.getItem("savings_asset")) || 0,
        student_debt: JSON.parse(localStorage.getItem("student_debt")) || 0
      }
      this.setState({
        values: localValues,
        score: JSON.parse(localStorage.getItem("score")) || 0,
        total: JSON.parse(localStorage.getItem("total")) || 0,
        email: localStorage.getItem("email") || ""
      })
    }
  }
  onCounterStart = () => {
    if (nodeRef.current !== null) {
      nodeRef.current.classList.add("active")
    }
  }
  onCounterEnd = () => {
    if (nodeRef.current !== null) {
      const counter = nodeRef.current
      counter.classList.remove("active")
      if (counter.innerHTML !== formatDollar(this.state.total)) {
        counter.innerHTML = formatDollar(this.state.total)
      }
    }
  }
  calculateTotal = numbers => {
    return Object.entries(numbers).reduce((finalValue, [key, value]) => {
      if (value === "") {
        return finalValue
      }
      return finalValue + value
    }, 0)
  }
  handleTotal = e => {
    const target = e.target
    const name = target.name
    const value =
      name.slice(-4) === "debt"
        ? -Math.abs(parseInt(target.value))
        : parseInt(target.value)
    const parsedValue = value ? value : 0

    this.setState(prevState => {
      const newVals = {
        ...prevState.values,
        [name]: parsedValue
      }
      const newTotal = this.calculateTotal(newVals)
      const {
        checking_asset: checking,
        savings_asset: savings,
        retire_asset: retire,
        roth_asset: roth,
        brokerage_asset: brokerage
      } = newVals
      const nonMortgageDebt =
        newVals.home_debt +
        newVals.credit_debt +
        newVals.medical_debt +
        newVals.other_debt +
        newVals.student_debt +
        newVals.property_debt
      let newStars = prevState.stars

      if (newTotal > 0) {
        // 1st half-star - Does the user have a net worth of at least $150,000?
        newTotal > 150000 ? (newStars[0] = 1) : (newStars[0] = 0)

        // 2nd half-star - Does the user have a net worth of at least $300,000?
        newTotal > 300000 ? (newStars[1] = 1) : (newStars[1] = 0)

        // 3rd half-star - Does the user have at least 10% of wealth or $500,000K in retirement accounts
        retire + roth > newTotal * 0.1 || retire + roth > 500000
          ? (newStars[2] = 1)
          : (newStars[2] = 0)

        // 4th half-star - [Duplcate of 3rd half-star]
        retire + roth > newTotal * 0.1 || retire + roth > 500000
          ? (newStars[3] = 1)
          : (newStars[3] = 0)

        // 5th half-star - Does the user have at least 30% of wealth in equity accounts?
        retire + roth + brokerage > newTotal * 0.3
          ? (newStars[4] = 1)
          : (newStars[4] = 0)

        // 6th half-star - Does the user have less than 25% of wealth in cash?
        checking + savings < newTotal * 0.25
          ? (newStars[5] = 1)
          : (newStars[5] = 0)

        // 7th half-star - Does user have zero debt besides mortgage?
        nonMortgageDebt === 0 ? (newStars[6] = 1) : (newStars[6] = 0)

        // 8th half-star - [Duplcate of 7th half-star]
        nonMortgageDebt === 0 ? (newStars[7] = 1) : (newStars[7] = 0)

        // 9th half-star - Does the user have at least $10k in a roth IRA? (half star)
        roth > 10000 ? (newStars[8] = 1) : (newStars[8] = 0)

        // 10th half-star - Does user have at least $20K or 10% of assets in cash?
        checking + savings > 20000 || checking + savings > newTotal * 0.1
          ? (newStars[9] = 1)
          : (newStars[9] = 0)
      } else {
        newStars.forEach((s, i) => (newStars[i] = 0))
      }

      let starCount = 0

      newStars.forEach(function(star) {
        star && starCount++
      })

      const newScore = starCount * 0.5

      if (typeof window !== "undefined") {
        localStorage.setItem("total", newTotal)
        localStorage.setItem("score", newScore)
        Object.entries(newVals).forEach(entry => {
          const [key, value] = entry
          localStorage.setItem(key, value)
        })
      }

      return {
        values: newVals,
        total: newTotal,
        prevTotal: prevState.total,
        stars: newStars,
        score: newScore
      }
    })
  }
  handleNext() {
    this.setState({
      page: this.state.page + 1
    })
    window.scrollTo(0, 0)
  }
  handleBack() {
    this.setState({
      page: this.state.page - 1
    })
    window.scrollTo(0, 0)
  }
  handleNav(e) {
    this.setState({
      page: parseInt(e.target.dataset.page)
    })
    window.scrollTo(0, 0)
  }
  handleSubmit(e) {
    if (typeof window !== "undefined") {
      if (localStorage.getItem("record")) {
        base("newsletter").update(
          [
            {
              id: localStorage.getItem("record"),
              fields: {
                email: this.state.email,
                total: this.state.total,
                score: this.state.score,
                ...this.state.values
              }
            }
          ],
          function(err, records) {
            if (err) {
              console.error(err)
              return
            }
            records.forEach(function(record) {
              // console.log(record.get("email"))
            })
          }
        )
      } else {
        base("newsletter").create(
          [
            {
              fields: {
                email: this.state.email,
                total: this.state.total,
                score: this.state.score,
                ...this.state.values
              }
            }
          ],
          function(err, records) {
            if (err) {
              console.error(err)
              return
            }
            records.forEach(function(record) {
              localStorage.setItem("record", record.getId())
            })
          }
        )
      }
    }
  }
  render() {
    const { page, score, total, prevTotal, values } = this.state
    return (
      <BodyClassName className="home">
        <div className="app">
          <div className="main">
            <div className="networth-wrapper">
              <div className="content">
                <div className="networth">
                  <div className="aside">
                    <div className="aside-total">
                      <CountUp
                        start={prevTotal}
                        end={total}
                        prefix={"$"}
                        separator={","}
                        duration={1}
                        delay={0}
                        easingFn={easingFn}
                        onStart={this.onCounterStart}
                        onEnd={this.onCounterEnd}
                      >
                        {({ countUpRef }) => {
                          nodeRef = countUpRef
                          return (
                            <span
                              className={classNames({
                                counter: true,
                                up: total > prevTotal,
                                down: prevTotal > total
                              })}
                              title={formatDollar(total)}
                              ref={countUpRef}
                            />
                          )
                        }}
                      </CountUp>
                      <Stars score={score} />
                      <div className="aside-total-title">Total net worth</div>
                    </div>
                    <div className="aside-items">
                      <div
                        className={classNames({
                          "aside-item": true,
                          active: page === 0
                        })}
                      >
                        <button data-page="0" onClick={this.handleNav}>
                          Cash
                        </button>
                      </div>
                      <div
                        className={classNames({
                          "aside-item": true,
                          active: page === 1
                        })}
                      >
                        <button data-page="1" onClick={this.handleNav}>
                          Brokerage & retirement
                        </button>
                      </div>
                      <div
                        className={classNames({
                          "aside-item": true,
                          active: page === 2
                        })}
                      >
                        <button data-page="2" onClick={this.handleNav}>
                          Real estate
                        </button>
                      </div>
                      <div
                        className={classNames({
                          "aside-item": true,
                          active: page === 3
                        })}
                      >
                        <button data-page="3" onClick={this.handleNav}>
                          Other assets
                        </button>
                      </div>
                      <div
                        className={classNames({
                          "aside-item": true,
                          active: page === 4
                        })}
                      >
                        <button data-page="4" onClick={this.handleNav}>
                          Debt
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="pages">
                    {
                      [
                        <div className="page page-1" key="page-1">
                          <div className="page-content">
                            <div className="page-info">
                              <h1>
                                Welcome to the simplest net worth calculator
                                ever.
                              </h1>
                              <p>
                                Net worth is your assets minus your liabilities,
                                and monitoring it is great way to start
                                measuring your financial fitness. Get started by
                                entering all your cash balances.
                              </p>
                            </div>
                            <div className="form">
                              <Input
                                name="checking_asset"
                                value={values.checking_asset}
                                onChange={this.handleTotal}
                              >
                                Checking accounts
                              </Input>
                              <Input
                                name="savings_asset"
                                value={values.savings_asset}
                                onChange={this.handleTotal}
                              >
                                Savings accounts
                              </Input>
                              <Input
                                name="cash_asset"
                                value={values.cash_asset}
                                onChange={this.handleTotal}
                                info="Any other cash that is not checking, savings, or brokerage account."
                              >
                                Other cash
                              </Input>
                            </div>
                            <PageFooter next={this.handleNext} />
                          </div>
                        </div>,
                        <div className="page page-2" key="page-2">
                          <div className="page-content">
                            <div className="page-info">
                              <h1>
                                How much is in your brokerage and retirement
                                accounts?
                              </h1>
                              <p>
                                This should include any holdings you have in
                                these accounts, including stocks and bonds, CDs,
                                and cash.
                              </p>
                            </div>
                            <div className="form">
                              <Input
                                name="brokerage_asset"
                                value={values.brokerage_asset}
                                onChange={this.handleTotal}
                              >
                                Personal brokerage
                              </Input>
                              <Input
                                name="retire_asset"
                                value={values.retire_asset}
                                onChange={this.handleTotal}
                                info="Traditional retirement accounts are funded with pre-tax income, meaning you delay paying taxes until you withdraw the funds."
                              >
                                Traditional 401k and IRA
                              </Input>
                              <Input
                                name="roth_asset"
                                value={values.roth_asset}
                                onChange={this.handleTotal}
                                info="Roth retirement accounts are funded with post-tax income, meaning you don't pay any taxes when you withdraw the funds."
                              >
                                Roth 401K and IRA
                              </Input>
                              <Input
                                name="hsa_asset"
                                value={values.hsa_asset}
                                onChange={this.handleTotal}
                                info="A health spending account is not the same as a flex spending account. It is very similar to a Roth IRA except that the proceeds can only be used for medical expenses."
                              >
                                HSAs
                              </Input>
                            </div>
                            <PageFooter
                              back={this.handleBack}
                              next={this.handleNext}
                            />
                          </div>
                        </div>,
                        <div className="page page-3" key="page-3">
                          <div className="page-content">
                            <div className="page-info">
                              <h1>How about your real estate holdings?</h1>
                              <p>
                                We calculate your real estate holdings by
                                subtracting your outstanding mortgages from your
                                current property values. You can find a current
                                estimate of your property values on most major
                                real estate sites.
                              </p>
                            </div>
                            <div className="form">
                              <Input
                                name="home_asset"
                                value={values.home_asset}
                                onChange={this.handleTotal}
                              >
                                Home value
                              </Input>
                              <Input
                                name="home_debt"
                                value={values.home_debt * -1 || 0}
                                onChange={this.handleTotal}
                                info="This is the amount you still owe on your home."
                              >
                                Mortgage balance
                              </Input>
                              <Input
                                name="property_asset"
                                value={values.property_asset}
                                onChange={this.handleTotal}
                              >
                                Other properties
                              </Input>
                              <Input
                                name="property_debt"
                                value={values.property_debt * -1 || 0}
                                onChange={this.handleTotal}
                                info="This is the amount you still owe on other properties."
                              >
                                Mortgages balances
                              </Input>
                            </div>
                            <PageFooter
                              back={this.handleBack}
                              next={this.handleNext}
                            />
                          </div>
                        </div>,
                        <div className="page page-4" key="page-4">
                          <div className="page-content">
                            <div className="page-info">
                              <h1>Any other assets?</h1>
                              <p>
                                Enter any additional assets you own. This can
                                include cryptocurrency, private equity, precious
                                metals, or other highly liquid valuables like
                                fine jewelry or art.
                              </p>
                            </div>
                            <div className="form">
                              <Input
                                name="equity_asset"
                                value={values.equity_asset}
                                onChange={this.handleTotal}
                                info="Shares in private companies are notoriously hard to value. Do your best based on the data available."
                              >
                                Private equity
                              </Input>
                              <Input
                                name="crypto_asset"
                                value={values.crypto_asset}
                                onChange={this.handleTotal}
                              >
                                Cryptocurrency
                              </Input>
                              <Input
                                name="metals_asset"
                                value={values.metals_asset}
                                onChange={this.handleTotal}
                              >
                                Precious metals
                              </Input>
                              <Input
                                name="other_asset"
                                value={values.other_asset}
                                onChange={this.handleTotal}
                                info="This should only include assets that have relatively high liquidity. For example fine art or collectibles for which there is an active market."
                              >
                                Other assets
                              </Input>
                            </div>
                            <PageFooter
                              back={this.handleBack}
                              next={this.handleNext}
                            />
                          </div>
                        </div>,
                        <div className="page page-5" key="page-5">
                          <div className="page-content">
                            <div className="page-info">
                              <h1>Now for the debt.</h1>
                              <p>
                                Anything you’ve spent or acquired that you are
                                still paying off goes here. This includes
                                student loans, credit card debt, auto loans,
                                personal loans, and medical debt. Don’t forget
                                any large items you’ve purchased on payment
                                plans, or recreational vehicles like boats or
                                RVs.
                              </p>
                            </div>
                            <div className="form">
                              <Input
                                name="student_debt"
                                value={values.student_debt * -1 || 0}
                                onChange={this.handleTotal}
                              >
                                Student loans
                              </Input>
                              <Input
                                name="medical_debt"
                                value={values.medical_debt * -1 || 0}
                                onChange={this.handleTotal}
                              >
                                Medical debt
                              </Input>
                              <Input
                                name="credit_debt"
                                value={values.credit_debt * -1 || 0}
                                onChange={this.handleTotal}
                              >
                                Credit cards
                              </Input>
                              <Input
                                name="other_debt"
                                value={values.other_debt * -1 || 0}
                                onChange={this.handleTotal}
                              >
                                Other debt
                              </Input>
                            </div>
                            <PageFooter
                              back={this.handleBack}
                              submit={this.handleSubmit}
                              jump="/score"
                            />
                          </div>
                        </div>
                      ][page]
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Header />
        </div>
      </BodyClassName>
    )
  }
}

export default IndexPage
